import react from 'react'
const Modal = ({event, closeModal, getData, ownName}) => {
    if (event.name===undefined) return <div></div>
    function deleteEvent() {
        fetch("https://www.server.k-mediapool.com/deleteevent",    {
            headers: {
                "Content-Type": "Application/json",
                "Accept": "Application/json"
            },
            method: "delete",
            credentials: "include",
            body: JSON.stringify({name: document.getElementById("name-update-input").value})
        }).then(response => response.json())
        .then(data => {if (data["error"]!=="accessDenied") {
            closeModal()
            getData()
        }})
    }
    function updateEvent() {
        let originalName = event.name
        let  name = document.getElementById("name-update-input").value
        let client = document.getElementById("client-update-input").value
        let clientdeputy = document.getElementById("client-deputy-update-input").value
        let start = document.getElementById("start-update-input").value
        let last = document.getElementById("end-update-input").value
        let email = document.getElementById("client-email-update-input").value
        fetch("https://www.server.k-mediapool.com/updateevent", {
            headers: {
                "Content-Type": "Application/json",
                "Accept": "Application/json"
            },
            credentials: "include",
            method: "put",
            body: JSON.stringify({

                originalname: originalName, 
                name: name, client: client, 
                clientdeputy: clientdeputy, 
                start: start,
                last: last,
                email: email
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data["error"]!=="accessDenied") {
                closeModal()
                getData()
            }
        })
    }
    function downloadImage(){
        fetch(`https://www.server.k-mediapool.com/lataa/${JSON.parse(event.filenames).name}`, {
            headers: {
                "content-type": "application/download"
            },
            credentials: "include"
        }).then(response => response.blob())
        .then(blob => {
            var file = window.URL.createObjectURL(blob);
            window.open(file, '_blank');
        })
    }
    console.log(event)

    let files = <p onClick={downloadImage}>{event.filenames.originalName}</p>
    let num = 0

    return <div className="modal-input-section">
            <h2>Voit muokata ja poistaa ainoastaan luomiasi kampanjoita</h2>
            <div>Kampanjan nimi <input defaultValue={event.name} key={event.id}  id="name-update-input" onChange={(e)=>{e.preventDefault()}}></input></div>
            <div>Asiakas <input defaultValue={event.client} key={event.id}  id="client-update-input"></input></div>
            <div>Asiakkaan edustaja <input defaultValue={event.clientdeputy} key={event.id} id="client-deputy-update-input"></input></div>
            <div>Asiakkaan sähköposti <input defaultValue={event.email} key={event.email}  id="client-email-update-input"></input></div>
            <div className="week-update-container">
                <div>
                <input key={event.id+"k"} className="week-update-input" id="start-update-input" defaultValue={event.start}></input> - <input key={event.id} className="week-update-input" id="end-update-input" defaultValue={event.last}></input>
                </div>
            </div>
            <div className="file-container"><h4>Tapahtuman tiedostot</h4>{files}</div>
            <div>
            <button className="update-button button" onClick={updateEvent}>Päivitä Kampanja</button>
            <button className="delete-button button" onClick={deleteEvent}>Poista Kampanja</button>
            </div>
        </div>
}

export default Modal