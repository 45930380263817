import react, { useEffect } from 'react'
import Calendar from './calendar.js'
function getDateOfWeek(w, y) {
    var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
  
    return new Date(y, 0, d);
  }
const CreateEvent = ({year, selected, activeEventType, changeForm,
    filteredEvents, 
    setSelected}) => {
    if (selected.length===0) return <div></div>
    window.onload=()=>changeForm(0)
    function handleTitleMove(id, ownid, offset) {
        if ( (offset===20)||offset===6&&document.getElementById(ownid).value==="")
        document.getElementById(id).style.bottom=`${offset}px`

    }
    async function handleSubmit(){
        let formData = new FormData()
        formData.append("name", document.getElementById("event-title-input").value)
        formData.append("selection", selected)
        formData.append("clientname", document.getElementById("client-name-input").value)
        formData.append("clientdeputy", document.getElementById("client-deputy-input").value)
        formData.append("producttype", activeEventType)
        formData.append("email", document.getElementById("client-email-input").value)
        let sizelist = document.getElementsByName("size")
        let size = Array.prototype.filter.call(sizelist, elem=>elem.checked===true)[0].value
        let data = {
            name: document.getElementById("event-title-input").value,
            selection: selected,
            clientname: document.getElementById("client-name-input").value,
            clientdeputy: document.getElementById("client-deputy-input").value,
            producttype: activeEventType,
            email: document.getElementById("client-email-input").value,
            size: size,
            year: year
        }

        formData.append("size", size)
        // Array.prototype.forEach.call(document.getElementById("file-input").file, child => {
        //   formData.append(`file${i}`, child)
        //   i+=1
        // });
        // formData.append("file", document.getElementById("file-input").files[0])

        formData.append("year", year)
    
        fetch("https://www.server.k-mediapool.com/addevent", {
          headers: {
            "Accept": "Application/Json",
            "Content-Type": "Application/Json"
          },
          credentials: "include",
          body: JSON.stringify(data),
          method: "post",
        })
        .then(res => res.json())
        .then(data => {
          if (data["success"]===true) {window.location.replace("/submit")}
        })




        // setSelected([])
        // window.setInterval(function(){getData()}, 50)
      }

    let viikot = "Viikot "

    if (selected.length===1) {viikot = "Viikko "}
    if (selected.length===0) {viikot = ""}
    selected.forEach(elem => {

        if (selected.indexOf(elem)===selected.length-2) {
            viikot+=`${elem} ja `
        }
        else if (selected.indexOf(elem)===selected.length-1) {
            viikot+=elem+`, ${year}`
        } else if (selected.indexOf(elem)===0) {viikot+=`${elem}, `}
        else {
            viikot += `${elem},`
        }
    })

    // let first = Math.min.apply(Math, selected);
    // let firstDate = getDateOfWeek(first, year)
    // let last = Math.max.apply(Math, selected);
    // let lastDate = getDateOfWeek(last, year)

    // console.log(selected)
    // console.log(first)
    // console.log(firstDate)
    // let dateText = `${firstDate.getDay()}.${firstDate.getMonth()}.${year}-${lastDate.getDay()}.${lastDate.getMonth()}.${year}`
    return (
        <div>
        <div className="calendar-input-area" method="post" enctype="multipart/form-data">
            <h4>{viikot}</h4>
            {/* <h4>{dateText}</h4> */}
        <div className="calendar-input-container">
            <h3 id="event-name-title">Varauksen nimi</h3>
            <input name="title" id="event-title-input" className="event-title-input" onFocus={()=>handleTitleMove("event-name-title", "event-title-input", 20)} onBlur={()=>handleTitleMove("event-name-title", "event-title-input", 6)}></input>
        </div>
        <div className="calendar-input-container">
            <h3 id="client-title">Asiakasyritys</h3>
            <input name="clientname" id="client-name-input" className="client-name-input" onFocus={()=>handleTitleMove("client-title", "client-name-input", 20)} onBlur={()=>handleTitleMove("client-title", "client-name-input", 6)}></input>
        </div >
        <div className="calendar-input-container">
            <h3 id="deputy-title">Asiakkaan edustaja</h3>
            <input name="clientdeputy" id="client-deputy-input" className="client-deputy-input" onFocus={()=>handleTitleMove("deputy-title", "client-deputy-input", 20)} onBlur={()=>handleTitleMove("deputy-title", "client-deputy-input", 6)}></input>
        </div>

        <div className="calendar-input-container">
            <h3 id="email-title">Asiakkaan sähköposti</h3>
            <input name="clientemail" id="client-email-input" className="client-email-input" onFocus={()=>handleTitleMove("email-title", "client-email-input", 20)} onBlur={()=>handleTitleMove("email-title", "client-email-input", 6)} ></input>
        </div>
              

        <h3 className="size-title">Valitse kampanjan koko</h3>
        <div className="size-input-container">
            <div>
                <h4>1/1</h4>
            <input type="radio" name="size" value="1/1" defaultChecked={true}></input>
            </div>
            <div>
                <h4>1/2</h4>
            <input type="radio" name="size" value="1/2"></input>
            </div>
        </div>
        <button onClick={()=>handleSubmit()} className="submit-event-button">Lisää varaus</button>
        </div>
    </div>)
    }
    export default CreateEvent