import react from 'react'

const Filter = ({handleChange, events}) => {
    return (
    <div className="filters-wrapper">
        <label for="tuoteryhmä"></label>
        {/* <select name="tuoteryhmä" id="type-filter-input" onChange={()=>handleChange(events)}>
            <option value="hälyhuppu">hälyhuppu</option>
            <option value="ostoskärrymainos">ostoskärrymainos</option>
        </select> */}
    </div>)
}
export default Filter