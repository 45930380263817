import react from 'react'
import { BrowserRouter, Link } from 'react-router-dom'
const axios = require("axios")
const Login = ({}) => {
    function handleLogin() {
        var username = document.getElementById("username").value
        var password = document.getElementById("password").value

        axios({
            method: "post",
            url: "https://www.server.k-mediapool.com/login",
            data: JSON.stringify({username: username, password: password}),
            withCredentials: true,
            headers: {
                "Content-Type": "Application/Json"
            }
          })
          .then(data => {
              console.log("kaljanen")
              console.log(data)
              if (!data["error"]) window.location.replace("/")
          })
        // console.log("kalja")
        
        // fetch("https://mediamyynti-server.herokuapp.com/login", {
        //     headers: {
        //         "Accept": "application/json",
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({username: username, password: password}),
        //     method: "POST",
        // })
    }
    return (
        <div className="register-input-container">
            <h1>Kirjaudu</h1>
            <input name="username" placeholder="Käyttäjänimi" id="username"></input>
            <input name="password" type="password" placeholder="Salasana" id="password"></input>
            <button onClick={()=>handleLogin()}>Kirjaudu</button>
        </div>
    )
}
export default Login