import React from "react";

const PageText = ({activeEventType, activeForm}) => {
  console.log(activeForm)

  let editPrompt = <p className="calendar-paragraph">Tarkastele varauskalenteria tai <span><a href="/varaa">luo uusi varaus</a></span></p>
  let categoryMap = {
    "hälyhuppu": "hälyhuput",
    "ostoskärrymainos": "ostoskärrymainokset"
  }
  let editInformation = <p>Voit tarkastella varauksia klikkaamalla viikon numeroa varauskalenterissa. Voit myös muuttaa itse tekemiäsi varauksia.
  </p>
  let calendarTitle = `Varauskalenteri, ${categoryMap[activeEventType]}`
  let calendarText = "Tervetuloa käyttämään K-Mediapoolia. Palvelu on tarkoitettu K-ryhmän mediapintojen varaamiseen ja tuotantoaineistojen toimittamiseen. Palvelun tarjoaa Coloro, joka myös tuottaa aineistot. "
  if (activeForm===0) {
    calendarTitle=`Luo uusi mediavaraus, ${activeEventType}`
    calendarText="Valitse kampanjan kesto viikkokalenterista. Täytä tarvittavat kentät ja tee varaus. Varauksen vahvistamisen jälkeen asiakkaalle lähtee linkki aineiston lataamiseksi."
    editPrompt=""
    editInformation=""
  }
  if (activeForm===2) {
    calendarTitle="K-Mediapool Keskon mediainventaari"
    editInformation=""
    editPrompt= <p className="calendar-paragraph">Tarkastele varauskalenteria tai luo uusi varaus</p>
    calendarText="Tervetuloa käyttämään K-Mediapoolia. Palvelu on tarkoitettu K-ryhmän mediapintojen varaamiseen ja tuotantoaineistojen toimittamiseen. Palvelun tarjoaa Coloro, joka myös tuottaa aineistot."
  }
    return (
    <div className="calendar-text-container">
      <h3 className="calendar-title">{calendarTitle}</h3>
      <p className="calendar-paragraph">{calendarText}</p>
      {editPrompt}
    </div>
    )
}
export default PageText