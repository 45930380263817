import react from 'react'
import { BrowserRouter, Link } from 'react-router-dom'

const Register = ({}) => {
    function handleRegister() {
        console.log("kalja")
        var username = document.getElementById("username").value
        var password = document.getElementById("password").value
        var firstname = document.getElementById("firstname").value
        var lastname = document.getElementById("lastname").value
        
        fetch("https://www.server.k-mediapool.com/register", {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({username: username, password: password, firstname: firstname, lastname: lastname}),
            method: "POST"
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            if (!data["error"]) window.location.href="/login"
        })
    }
    return (
        <div className="register-input-container">
            <h1>Rekisteröityminen</h1>
            <input name="firstname" placeholder="Etunimi" id="firstname"></input>
            <input name="lastname" placeholder="Sukunimi" id="lastname"></input>
            <input name="username" placeholder="Käyttäjänimi" id="username"></input>
            <input name="password" type="password" placeholder="Salasana" id="password"></input>
            <button onClick={()=>handleRegister()}>Rekisteröidy</button>
            <h3>Onko sinulla jo käyttäjä?</h3>
            <button onClick={()=>{window.location.replace("/login")}}>Kirjaudu sisään täällä</button>
        </div>
    )
}
export default Register