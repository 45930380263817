import react from 'react'

const eventWindow = ({events, year, hovered}) => {
    events = events.filter(event => {
        return (event.year===year)
    })

    events = events.sort((a,b) => {
        if ((a.start<=hovered&&a.last>=hovered)&&((b.start>hovered)||b.last<hovered)){return -1}
        else if ((a.start<=hovered&&a.last>=hovered)&&(b.start<=hovered&&b.last>=hovered)) {return 0}
        else return 1
    })

    let eventsList = events.map(event => {
        let style = {}
        if (event.start<=hovered&&event.last>=hovered) {
            style = {border: "4px solid red"}
        }
        return <div className="single-event-wrapper" style={style}>
            <h1 className="event-duration">weeks {event.start}-{event.last} <div><span className="event-title">{event.title}</span></div></h1>
            <h2>{event.author}</h2>
        </div>
    })


    return (<div className="events-wrapper">
        {eventsList}
    </div>)
}
export default eventWindow