import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import "./stylesheet.css"
import {BrowserRouter,Route} from 'react-router-dom'
import Register from './components/register.js'
import Calendar from './components/calendar.js'
import Login from './components/login.js'
import AddImage from './components/addImage.js';
import Management from './components/management.js';
import AfterSubmit from './components/afterSubmit.js'
import UpdateEvent from './components/updateEvent'
import TopBar from './components/topbar'
import CreateEvent from './components/createEvent'
import PageText from './components/pageText'
import ResetPassword from './components/resetpassword'

function App() {
  let address = window.location.href.split("/")[3]

  const [data, setData]=useState([])
  const [events, setEvents]=useState([
//     {author: "rikumatt",
//     year: 2021,
// client: "asdf",
// clientdeputy: "asdf",
// email: "asdf",
// filenames: {},
// id: "96f3a092-7646-4e37-9639-7ec4d811154f",
// last: 40,
// name: "adf",
// producttype: "hälyhuppu",
// size: "1/1",
// start: 39,
// tokenhash: "$2b$10$MOeT6h9XNnYX82d6lY13DePles.RPJPcXMvlurCA5pYgp9/iXbvC2",},

// {author: "kanku",
// year: 2021,
// client: "matilaknen",
// clientdeputy: "asdf",
// email: "asdf",
// filenames: {},  
// id: "96f3a092-7646-4e37-9639-7ec4d811154f",
// last: 40,
// name: "adf",
// producttype: "ostoskärrymainos",
// size: "1/1",
// start: 39,
// tokenhash: "$2b$10$MOeT6h9XNnYX82d6lY13DePles.RPJPcXMvlurCA5pYgp9/iXbvC2",}
])
  const D = new Date()
  const [year, setYear]=useState(2023)
  const [selected, setSelected] = useState([])
  const [filteredEvents, setFilteredEvents] = useState(events)
  const [activeEvent, setActiveEvent]=useState([])
  const [activeForm, setActiveForm] = useState({"":2, "varaa":0, "varauskalenteri":1}[address]) 
  const [activeEventType, setActiveEventType]=useState("hälyhuppu")
  const [admin, setAdmin] = useState(false)

  function getAuthToken(func) {
    fetch("https://www.server.k-mediapool.com/generateaccesstoken", {
      credentials: "include"
    })
    .then(res => res.json())
    .then(data => {

      if (!data["validToken"]&& !window.location.href.includes("addimage")){
        window.location.href="/login"
      } else {
        func()
      }
    })
  }

  function handleFilterChange(list, form, category) {

    let searchValue = document.getElementById("search-bar-input").value

    let visibleEvents = list.filter(event => {

      return (Object.keys(event).some(key => {
        return (event[key].toString().includes(searchValue)&&key!=="filenames")&&event.producttype===category}))
    })

    setFilteredEvents(visibleEvents)
    setActiveEvent([])
    setSelected([])
    setActiveForm(form)
    setActiveEventType(category)
  }

  async function getData(){
    console.log("hi")
    if (window.location.href.includes("addimage")) {
      return
    }
    fetch("https://www.server.k-mediapool.com/getdata", {
      credentials: "include"
    })
    .then(res => {
      if (window.location.href.includes("addimage")) {
        return "sij"
      }
      return res.json()
    })
    .then(data => {
      console.log(data)
      console.log(data)
      if (data["validToken"]!==true){
        getAuthToken(getData)
      } else {
        setAdmin(data["admin"])
        if (data["events"]!==undefined) {
          setEvents(data["events"])
          handleFilterChange(data["events"], activeForm, activeEventType)
        }
      }
    })
  }
  function handleFilterChange(list, form, category) {

    let searchValue = document.getElementById("search-bar-input").value

    let visibleEvents = list.filter(event => {
      return (Object.keys(event).some(key => {
        return (event[key].toString().includes(searchValue)&&key!=="filenames")&&event.producttype===category}))
    })

    setFilteredEvents(visibleEvents)
    setActiveEvent([])
    setSelected([])
    setActiveForm(form)
    setActiveEventType(category)
  }
  function changeForm(num) {
    setActiveEvent([])
    setSelected([])
    setActiveForm(num)
  }
  useEffect(function(){
    if (!["login", "register"].some(elem=>window.location.href.includes(elem))) {
      getData()
    }
  }, [])
  return (
    <div className="App">
      <BrowserRouter>

      <Route path="/" component={()=><TopBar handleFilterChange={handleFilterChange} events={events} activeCategory={activeEventType} activeForm={activeForm} changeForm={changeForm} handleChange={handleFilterChange} activeEventType={activeEventType} admin={admin}/>}></Route>
      <Route exact path={["/", "/varauskalenteri", "/varaa"]} component={()=><PageText activeEventType={activeEventType} activeForm={activeForm}></PageText>}></Route>
      <Route path="/addimage" component={()=><AddImage></AddImage>}></Route>
      <Route path="/management" component={()=><Management/>}></Route>
      {/* <Route path={["/varauskalenteri", "/varaa"]} component={()=><Calendar 

      getAuthToken={getAuthToken} filteredEvents={filteredEvents} selected={selected} 
      setSelected={setSelected} activeEvent={activeEvent} setActiveEvent={setActiveEvent} 
      activeEventType={activeEventType} events={events} getData={getData} year={year} setYear={setYear}/>}></Route> */}
      <Route path={["/varaa", "/varauskalenteri"]} component={()=>
      <Calendar getAuthToken={getAuthToken} filteredEvents={filteredEvents} selected={selected} 
      setSelected={setSelected} activeEvent={activeEvent} setActiveEvent={setActiveEvent} 
      activeEventType={activeEventType} events={events} getData={getData} year={year} setYear={setYear}/>
      }></Route>
      <Route path="/varaa" component={()=><CreateEvent  selected={selected} activeEventType={activeEventType} 
      year={year} changeForm={changeForm} year={year} getAuthToken={getAuthToken} filteredEvents={filteredEvents} selected={selected} 
      setSelected={setSelected} activeEvent={activeEvent} setActiveEvent={setActiveEvent} 
      activeEventType={activeEventType} events={events} getData={getData} year={year} setYear={setYear}></CreateEvent>}></Route>

      <Route path="/varauskalenteri" component={()=><UpdateEvent getData={getData} changeForm={changeForm} events={activeEvent} admin={admin}></UpdateEvent>}></Route>
      <Route path="/register" component={()=><Register getAuthToken={getAuthToken}></Register>}></Route>
      <Route path="/login" component={()=><Login getAuthToken={getAuthToken}></Login>}></Route>
      <Route path="/submit" component={() => <AfterSubmit></AfterSubmit>}/>
      <Route path="/reset" component={() => <ResetPassword/>}></Route>
      </BrowserRouter>
    </div>
  );
}

export default App;
