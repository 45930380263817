















import react from 'react'

const Management = ({}) => {
    function handleTitleMove(id, ownid, offset) {
        if ( (offset===20)||offset===6&&document.getElementById(ownid).value==="")
        document.getElementById(id).style.bottom=`${offset}px`
        console.log("kalja")
    }
    
    let fieldData = [
        ["Etunimi", "firstname"], 
        ["Sukunimi", "lastname"], 
        ["Käyttäjänimi (sähköposti)", "username"],
        ["Salasana", "password"]
    ]
    let fieldData2=[
        ["Käyttäjän sähköposti", "username2"],
        ["Uusi salasana", "password2"]
    ]
    function createFields(arr) {
        return arr.map(elem => {
            return (
        <div className="create-user-input-container">
            <h3 id={`${elem[1]}-title`}>{elem[0]}</h3>
            <input name="clientdeputy" id={elem[1]} onFocus={()=>handleTitleMove(`${elem[1]}-title`, elem[1], 20)} onBlur={()=>handleTitleMove(`${elem[1]}-title`, elem[1], 6)}></input>
        </div>
            )
        })
    }
    function handleRegister() {
        console.log("kalja")
        var username = document.getElementById("username").value
        var password = document.getElementById("password").value
        var firstname = document.getElementById("firstname").value
        var lastname = document.getElementById("lastname").value
        
        fetch("https://www.server.k-mediapool.com/register", {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({username: username, password: password, firstname: firstname, lastname: lastname}),
            method: "POST"
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
        })
    }
    function handlePasswordChange() {
        let username=document.getElementById("username2").value
        let password = document.getElementById("password2").value
        fetch("https://www.server.k-mediapool.com/changepassword", {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({username: username, password: password}),
            method: "PUT"
            })
        }
    return (
        <div className="management-container">
            <h1>Hallinta</h1>
            <div className="create-user-container">
                <div>
                    <h3>Luo uusi käyttäjä</h3>
                    {createFields(fieldData)}
                    <button onClick={()=>handleRegister()}>Luo Käyttäjä</button>
                </div>
                <div>
                    <h3>Vaihda käyttäjän salasana</h3>
                    {createFields(fieldData2)}
                    <button onClick={()=>handlePasswordChange()}>Vaihda salasana</button>
                </div>
            </div>
        </div>
    )
}
export default Management