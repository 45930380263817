import react from 'react'

const InfoBox = ({hovered, filteredEvents}) => {
    let hoveredEvent = filteredEvents.filter(elem => {
        return (hovered>=elem.start&&hovered<=elem.end)
    })[0]
    if (hoveredEvent===undefined) return <div></div>

    return (
        <div className="info-box-container">
            <h1>Kampanjan nimi: {hoveredEvent.name}</h1>
            <h2>asiakas: {hoveredEvent.client}</h2>
        </div>
    )
}
export default InfoBox