import react from 'react'
import { useEffect, useState } from 'react'
const AddImage = ({}) => {
    const [messageStyle, setMessageStyle]=useState({visibility: "hidden"})
    const [loadingStyle, setLoadingStyle] = useState({visibility: "hidden"})



    function handleSubmit() {
        setLoadingStyle({visibility: "visible"})
        let formData = new FormData()
        let token = window.location.href.split("/addimage/")[1]
        formData.append("file", document.getElementById("file-input").files[0])
        fetch(`https://www.server.k-mediapool.com/addimage/${token}`, {
            method: "post",
            body: formData
        })
        .then(res => res.json())
        .then(data => {
            setLoadingStyle({visibility: "hidden", height: "0px"})
            setMessageStyle({visibility: "visible"})
        })
    }

    console.log("here")
    let eventName = ""
    let [nameSettings, setNameSettings] = useState(["green", ""])
    console.log(window.location.href.split("addimage/")[1])
    useEffect(function(){
        let token = window.location.href.split("addimage/")[1]
        console.log(token)
        fetch(`https://www.server.k-mediapool.com/addimage/${token}`, {
            credentials: "include"
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            if (data["error"]===true) {
                setNameSettings(["red", "Linkkisi on virheellinen tai ei ole enää voimassa."])
            } else {
                setNameSettings(["green", `Lisää tiedosto kampanjaan ${data.post}`])
            }

    })
    }, [])
    let token = window.location.href.split("/addimage/")[1]
    return (
    <div className="add-image-container">
        <h1 style={{color: nameSettings[0]}}>{nameSettings[1]}</h1>
        <div>
            <input type="file" name="file" id="file-input" multiple></input>
            <button onClick={()=>handleSubmit()}>Lähetä</button>
        </div>
        <img src="/loading.png" className="loading-icon" style={loadingStyle}></img>
        <h1 className="uploaded-message" style={messageStyle}>Tiedosto ladattu</h1>
    </div>)
}
export default AddImage