import react from 'react'
import { Link } from 'react-router-dom'
const TopBar = ({handleFilterChange, events, activeCategory, activeForm, changeForm, handleChange, activeEventType, admin}) => {
    function handleClick(form, category) {
        handleFilterChange(events, form, category)
    }
    function handleLogOut() {
        fetch("https://www.server.k-mediapool.com/logout", {
            credentials: "include"
        })
        .then(response => 
            {window.location.replace("/login")
        })
    }
    function toggleSearch(num) {
        document.getElementById("searchbar-container").style.width=`${460*num}px`
        console.log(`${460*num}px`)
        console.log(num)
        let visibilityMap={0: "visible", 1: "hidden"}
        document.getElementById("search-icon").style.transitionDelay="0ms"
        if (num===0) {document.getElementById("search-icon").style.transitionDelay="200ms"}
        else {document.getElementById("search-bar-input").focus()}
        document.getElementById("search-icon").style.visibility=visibilityMap[num]
    }
    let pageTitle = "K-Mediapool Keskon mediainventaari"
    if (activeForm===0) {
        let categoryMap = {
            "hälyhuppu": "hälyhuput",
            "ostoskärrymainos": "ostoskärrymainokset"
        }
        pageTitle=`Varauskalenteri ${categoryMap[activeCategory]}`
    }
    let adminButton = <div></div>
    if (admin) {
        adminButton=<Link to="/management"><button className="management-button topbar-button">Hallinta</button></Link>
    }
    let contentStyle = {visibility: "visible"}
    if (window.location.href.includes("/login")) {
        contentStyle={visibility: "hidden"}
    }
    return  <div className="topbar">
            <div className="topbar-border"></div>
        <div className="topbar-content">
            <a href="/"><img src="/kesko.jpg" className="kesko-logo"></img></a>
            {/* <h3 className="page-title">{pageTitle}</h3> */}
            <div className="topbar-options" style={contentStyle}>
            
            <div className="varauskalenteri-dropdown topbar-dropdown">
                <h2 className="dropdown-title">VARAUSKALENTERI <div className="arrow"><div/><div/></div></h2>
                <div className="dropdown-options-container">
                    <Link to="/varauskalenteri">
                        <div className="dropdown-option" onClick={()=>handleClick(1, "hälyhuppu")}>
                            <h4 >HÄLYHUPPU</h4>
                        </div>
                    </Link>
                    {/* <Link to="/varauskalenteri">
                        <div className="dropdown-option" onClick={()=>handleClick(1,"ostoskärrymainos")}>
                        <h4 >OSTOSKÄRRYMAINOS</h4>
                        </div>
                    </Link> */}
                </div>
            </div>
            <div className="varauskalenteri-dropdown topbar-dropdown">
                <h2 className="dropdown-title">TEE UUSI VARAUS <div className="arrow"><div/><div/></div></h2>
                <div className="dropdown-options-container">
                <Link to="/varaa">
                <div className="dropdown-option" onClick={()=>handleClick(0, "hälyhuppu")}>
                <h4 >HÄLYHUPPU</h4>
                </div>
                </Link>
                {/* <Link to="/varaa">
                    <div className="dropdown-option" onClick={()=>handleClick(0, "ostoskärrymainos")}>
                        <h4 onClick={()=>handleClick(0, "ostoskärrymainos")}>OSTOSKÄRRYMAINOS</h4>
                    </div>
                </Link> */}
                </div>
            </div>
            <div className="search-container">
            <div className="searchbar-container" id="searchbar-container">
            <img src="/search_icon.png"></img>
            <input id="search-bar-input" onBlur={()=>toggleSearch(0)} placeholder="Hae Keskon mediainventaarista" onChange={()=>handleChange(events, activeForm, activeEventType)}></input>
            <img className="close_search-icon" onClick={()=>toggleSearch(0)} src="/close_search.png"></img>
            </div>
            <img className="search-icon" onClick={()=>toggleSearch(1)} id="search-icon" src="/search_icon.png"></img>
            </div>
            <div className="side-button-container">
            {adminButton}
            <button className="topbar-button" onClick={()=>handleLogOut()}>Kirjaudu ulos</button>
            <a href="/reset"><button className="topbar-button">Vaihda Salasana</button></a>
            </div>
            </div>
        </div>
    </div>
}
export default TopBar