function handleTitleMove(id, ownid, offset) {
    if ( (offset===20)||offset===6&&document.getElementById(ownid).value==="")
    document.getElementById(id).style.bottom=`${offset}px`
    console.log("kalja")
}

function handlePasswordChange() {
    let oldpass = document.getElementById("old_password").value
    let newpass = document.getElementById("new_password").value
    let newpass2 = document.getElementById("new_password_2").value
    fetch("https://www.server.k-mediapool.com/resetpassword", {
        headers: {
            "Accept": "Application/Json",
            "Content-Type": "Application/Json"
            },
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({old_password: oldpass, new_password: newpass, new_password_2: newpass2})
        }
    )
    .then(response => response.json())
    .then(data => {
        console.log(data)
    })
}

function createFields(arr) {
    return arr.map(elem => {
        return (
    <div className="create-user-input-container">
        <h3 id={`${elem[1]}-title`}>{elem[0]}</h3>
        <input name="clientdeputy" id={elem[1]} onFocus={()=>handleTitleMove(`${elem[1]}-title`, elem[1], 20)} onBlur={()=>handleTitleMove(`${elem[1]}-title`, elem[1], 6)}></input>
    </div>
        )
    })
}

let fieldData = [
    ["Vanha salasana", "old_password"], 
    ["Uusi salasana", "new_password"], 
    ["Toista uusi salasana", "new_password_2"],
]


const ResetPassword = ({}) => {
    return (
    <div className="reset-password-container">
        <h1>Vaihda salasanasi</h1>
        {createFields(fieldData)}
        <button onClick={()=>handlePasswordChange()}>Vaihda salasana</button>
    </div>)
}
export default ResetPassword