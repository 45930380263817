import react from 'react'
const UpdateEvent = ({getData, events, changeForm, admin}) => {
    console.log(events)
    window.onload=()=>changeForm(1)
    console.log("kalja")
    if (events===undefined || events[0]===undefined) return <div></div>


    let event = events[0]
    console.log(event)
    function handleTitleMove(id, ownid, offset) {
        if ( (offset===20)||offset===6&&document.getElementById(ownid).value==="")
        document.getElementById(id).style.bottom=`${offset}px`
        console.log("kalja")
    }

    function deleteEvent() {
        fetch("https://www.server.k-mediapool.com/deleteevent",    {
            headers: {
                "Content-Type": "Application/json",
                "Accept": "Application/json"
            },
            method: "delete",
            credentials: "include",
            body: JSON.stringify({id: event.id})
        }).then(response => response.json())
        .then(data => {if (data["error"]!=="accessDenied") {
            getData()
        }})
    }

    function downloadImage(){
        console.log("download started")
        console.log(`https://www.server.k-mediapool.com/lataa/${event.filenames.name}/${event.name}`)
        fetch(`https://www.server.k-mediapool.comlataa/${event.filenames.name}/${event.name}`, {
            headers: {
                "content-type": "application/download",
            },
            method: "post",
            credentials: "include"
        }).then(response => response.blob())
        .then(blob => {
            var file = window.URL.createObjectURL(blob);
            window.open(file, '_blank');
        })
    }


    console.log(event)  
    console.log(event.filenames)
    function updateEvent(num) {
        let formData = new FormData()
        formData.append("name", document.getElementById("name-update-input"+num).value)
        formData.append("client", document.getElementById("client-update-input"+num).value)
        formData.append("clientdeputy", document.getElementById("client-deputy-update-input"+num).value)
        formData.append("email", document.getElementById("client-email-update-input"+num).value)
        let sizelist = document.getElementsByName("size"+num)
        let size = Array.prototype.filter.call(sizelist, elem=>elem.checked===true)[0].value
        formData.append("size", size)
        formData.append("originalname", events[num-1].name)
        formData.append("id", events[num-1].id)
        formData.append("file", document.getElementById("file-input"+num).files[0])
        // let originalName = event.name
        // let  name = document.getElementById("name-update-input").value
        // let client = document.getElementById("client-update-input").value
        // let clientdeputy = document.getElementById("client-deputy-update-input").value
        // let email = document.getElementById("client-email-update-input").value
        // let size = document.getElementById("")
        fetch("https://www.server.k-mediapool.com/updateevent", {
            headers: {"Accept": "application/json"},
            credentials: "include",
            method: "put",
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data["error"]===false) {
                getData()
            }
        })
    }
    let num = 0
    let forms = events.map(event => {
    console.log(event.size)
    num+=1
    return (
        <div className="calendar-input-area" method="post" enctype="multipart/form-data">
            <h2>K-Mediapool Keskon mediainventaari</h2>
        <div className="calendar-input-container">
            <h3 style={{bottom: "20px"}} id={"event-name-title"+num}>Varauksen nimi</h3>
            <input key={event.name} defaultValue={event.name} name="title" id={"name-update-input"+num} className="event-title-input" onFocus={()=>handleTitleMove("event-name-title"+num, "name-update-input"+num, 20)} onBlur={()=>handleTitleMove("event-name-title"+num, "name-update-input"+num, 6)}></input>
        </div>
        <div className="calendar-input-container">
            <h3 style={{bottom: "20px"}} id={"client-title"+num}>Asiakasyritys</h3>
            <input key={event.client} name="clientname" defaultValue={event.client} id={"client-update-input"+num} className="client-name-input" onFocus={()=>handleTitleMove("client-title"+num, "client-update-input"+num, 20)} onBlur={()=>handleTitleMove("client-title"+num, "client-update-input"+num, 6)}></input>
        </div >
        <div className="calendar-input-container">
            <h3 style={{bottom: "20px"}} id={"deputy-title"+num}>Asiakkaan edustaja</h3>
            <input key={event.clientdeputy} defaultValue={event.clientdeputy} name="clientdeputy" id={"client-deputy-update-input"+num} className="client-deputy-input" onFocus={()=>handleTitleMove("deputy-title"+num, "client-deputy-update-input"+num, 20)} onBlur={()=>handleTitleMove("deputy-title"+num, "client-deputy-update-input"+num, 6)}></input>
        </div>

        <div className="calendar-input-container">
            <h3 style={{bottom: "20px"}} id={"email-title"+num}>Asiakkaan sähköposti</h3>
            <input key={event.email} defaultValue={event.email} name="clientemail" id={"client-email-update-input"+num} className="client-email-input" onFocus={()=>handleTitleMove("email-title"+num, "client-email-update-input"+num, 20)} onBlur={()=>handleTitleMove("email-title"+num, "client-email-update-input"+num, 6)} ></input>
        </div>
        <p onClick={()=>downloadImage()}>{event.filenames.originalName}</p>
        <input type="file" name="file" id={"file-input"+num} multiple></input>
        <h3 className="size-title">Valitse kampanjan koko</h3>
        <div className="size-input-container">
            <div>
                <h4>1/1</h4>
            <input key={`${event.size}1-${num}`} type="radio" name={"size"+num} value="1/1" defaultChecked={(event.size==="1/1")}></input>
            </div>
            <div>
                <h4>1/2</h4>
            <input key={`${event.size}2-${num}`} type="radio" name={"size"+num}  value="1/2" defaultChecked={(event.size==="1/2")}></input>
            </div>
        </div>
        <button onClick={()=>updateEvent(num)} className="submit-event-button event-update-button">Muuta varausta</button>
        <button onClick={()=>{deleteEvent(num)}} className="delete-event-button event-update-button">Peruuta varaus</button>
        </div>)
        })
        return <div className="update-forms-wrapper">{forms}</div>
    }
    export default UpdateEvent